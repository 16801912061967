import React from 'react';
import { Container, Row, Col, Button, Navbar, NavbarBrand, NavbarToggler, Nav, Collapse, NavItem, NavLink } from 'reactstrap';

import config from './config';

import './Navigation.scss';
import Logo from '../Assets/Logo';

class Navigation extends React.Component {
  state = {
    activeTab: 'home',
    isOpen: false
  }

  componentDidMount() {
    const options = {
      threshold: 0.45
    }

    const observer = new IntersectionObserver(this.handleIntersect, options);
    config.forEach(el => {
      let ele = document.getElementById(el.id);
      observer.observe(ele);
    });

    observer.observe(document.getElementById('home'));


    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();

          const el = document.querySelector(this.getAttribute('href'));
          const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = -55;

          window.scrollTo({
            top: yCoordinate + yOffset,
            behavior: 'smooth'
          });

          window.history.pushState(null, '', this.getAttribute('href'))
      });
    });
  }

  toggle = () => (this.setState({ isOpen: !this.state.isOpen }))

  handleIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting && entry.intersectionRatio >= 0.45) {
        var id = entry.target.getAttribute('id');
        this.setState({ activeTab: id });
      }
    });
  }

  renderDesktopNavigationElements = () => {
    const { activeTab } = this.state;

    return config.map((el, i) => (
      <Col xs='auto' className={i === 0 ? 'ml-auto' : ''} key={`desktop-nav-${i}`}>
        <Button color='link' onClick={() => el.onClick(this)} href={`#${el.id}`}>
          {el.label}
        </Button>
      </Col>
    ));
  }

  renderDesktopNavigation = () => (
    <div className='desktop-nav' id='navbar'>
      <Container>
        <Row noGutters className='align-items-center'>
          <Col xs='auto'>
            <img src='/images/kfmx-logo.png' alt="Kerflummox Capital, LLC" height="48px" />
          </Col>
          {this.renderDesktopNavigationElements()}
          <Col xs='auto'>
            <Button color='primary' outline href='#contact' style={{ borderRadius: '24px', borderWidth: '2px', marginLeft: '15px', padding: '8px 24px' }}>
              Contact
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )

  renderMobileNavbarElements = () => {
    return config.map((el, i) => (
      <NavItem key={`mobile-el-${i}`}>
        <NavLink onClick={() => el.onClick(this)} href={`#${el.id}`}>{el.label}</NavLink>
      </NavItem>
    ));
  }

  renderMobileNavigation = () => (
    <div className='mobile-nav'>
      <Navbar light expand="md">
        <NavbarBrand href="#home" onClick={() => this.state.isOpen && this.toggle()}>
          <img src='/images/kfmx-logo.png' alt="Kerflummox Capital, LLC" height='36px' />  
          {/* <div style={{ fontSize: '21px', fontWeight: '500', color: 'var(--primary)' }}>
            <Logo height={48} />            
          </div> */}
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {this.renderMobileNavbarElements()}
            <NavItem>
              <NavLink onClick={this.toggle} href={`#contact`}>Contact</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
  
  render = () => (
    <div className='Navigation'>
      {this.renderDesktopNavigation()}
      {this.renderMobileNavigation()}
    </div>
  )
}

export default Navigation;