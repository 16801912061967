import React from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';

import './Team.scss';

const config = [
  {
    name: 'Aimahd Sabky',
    title: 'Partner Kerflummox Capital, co-founder Bema Holdings - management and operations',
    description: 'Aimahd is the chief operating officer for Kerflummox and Bema companies and is responsible for product development and operations. Aimahd is a co-founder at CollegeLab and CyberBuoy. Before Kerflummox Capital, Aimahd worked at MakeSpace. Aimahd graduated from Amherst College in 2014 with a degree in Mathematics and from The Middlesex School in Concord, MA in 2010.'
  },
  {
    name: 'Jamal Sabky',
    title: 'Partner Kerflummox Capital, co-founder Bema Holdings – executive management, business development',
    description: 'Jamal is a serial entrepreneur, and co-founder of CollegeLab, an algorithm-enabled college admissions service, PreventAGEHealth Care, a diabetes diagnostic firm; CyberBuoy, a cyber insurance and response service; Kings Row Coffee, a cold brew coffee company, and Lutum Technology, a molecularly-imprinted polymer sensor development company focused on industrial, security, and health-care applications. Jamal received his undergraduate degree from Dartmouth College.'
  },
  {
    name: 'George Faux',
    title: 'Founder Lone Pine Ventures, co-founder Bema Holdings – executive management, business development',
    description: <div><p>George is a Senior Partner, International Advisory Partners, a firm that counsels public and private entities on economic, commercial and infrastructure developments; a co-founder of CollegeLab, an algorithm-enabled college admissions service, and a co-founder Lutum Technology, a molecularly-imprinted polymer sensor development company focused on industrial, security, and health-care applications.</p>
      <p>Previously, George was an executive at Fortent, a joint venture with Warburg Pincus, and spent 18 years managing many of Citi Group's business globally. George received his undergraduate degree from Dartmouth College and a master's degree from the Johhs Hopkins School of Advanced International Studies.</p></div>
  },
  {
    name: 'Don Stephan',
    title: 'Partner Bema Technologies - lead developer, manager technical operations',
    description: 'Don is the Technical Operations Manager and Lead developer of for Kerflummox Capital\'s companies where he manages all application and development operations. He is also the co-founder of Spatial Cinematics, a mixed-reality company in Austin, TX. Don received an undergraduate degree from Dartmouth College.'
  },
  {
    name: 'S. Caroline Kerr',
    title: 'Partner Kerflummox Capital - corporate strategy and business development',
    description: 'Caroline is a builder, connector, and cultivator, who has led organizations through significant phases of growth and innovation. Previously, she served as CEO of the Joyce Ivy Foundation, an organization that broadens college aspirations and leadership development of talented young women, and has deep expertise in the college admissions and education space. She builds and advises organizations on strategy, growth, and governance. Caroline serves on multiple boards, including Dartmouth, Nelson A. Rockefeller Center for Public Policy and Social Sciences, and CollegeLab. Caroline received her undergraduate degree from Dartmouth College and a master\'s degree from Harvard.'
  },
  {
    name: 'Christian Davies',
    title: 'Director of innovation and insurance',
    description: 'Christian is a highly regarded innovator and technologist in the cyber, media, and technology insurance world. Most recently, Christian lead innovation and insurance at CyberBuoy, a personal cyber insurance start-up. Previously, Christian set up and ran the Technology, Media, and Cyber Insurance practice at EC3 Brokers, a Lloyd\'s of London broker, where he placed complex insurances for some of the largest and fastest growing companies in technology over the last 7 years. Christian has worked with companies all over the globe.'
  },
  {
    name: 'Chris Goulet',
    title: '',
    description: ''
  },
  {
    name: 'Brant Sanborn',
    title: '',
    description: ''
  },
  {
    name: 'Todd White',
    title: '',
    description: ''
  },
  {
    name: 'John Ruth',
    title: '',
    description: ''
  },
  {
    name: 'Abbie Montgomery',
    title: '',
    description: ''
  },
  {
    name: 'Sharon Ellman',
    title: '',
    description: ''
  },
]

const LinkedInButton = ({ url }) => (
  <div style={{ display: 'inline-block', marginLeft: '8px' }} className='linkedin'>
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <img src='/linkedin.png' height={20} />
    </a>
  </div>
)

class TeamMember extends React.Component {
  state = {
    isModalOpen: false
  }

  toggle = () => (this.setState({ isModalOpen: !this.state.isModalOpen }));

  renderModal() {
    const { isModalOpen } = this.state;
    const { e } = this.props;

    return (
      <Modal isOpen={isModalOpen} toggle={this.toggle} size='lg'>
        <ModalHeader toggle={this.toggle}>{e.name}</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: '18px', marginBottom: '1rem' }}>{e.title}</div>
          <div style={{ paddingBottom: '0.5rem' }}>{e.description}</div>
        </ModalBody>
      </Modal>
    )
  }

  render() {
    const { e } = this.props;

    return (
      <div className='team-member' onClick={this.toggle}>
        <div className='name'>{e.name}</div>
        <div className='title'>{e.title}</div>
        {this.renderModal()}
      </div>
    )
  }
}

class Team extends React.Component {
  renderTeam = () => {
    let length = config.length;

    return config.map((e, i) => (
      <Col xs='12' sm='12' md={length % 3 === 1 && i >= length - 4 ? '6' : '4'} key={`team-${i}`}>
        <TeamMember e={e} />
      </Col>
    ));
  }

  render = () => (
    <div className='Team' id='team'>
      <Container>
        <Row>
          <Col xs='12' sm='12' md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <h1>Our Team</h1>
            <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '64px', color: '#555c63' }}>This is the team that will bring your company to life.</p>
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Jamal Sabky</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Principal</h5>
            {/* <p style={{ color: '#555c63' }}>Jamal is a serial entrepreneur, and co-founder of CollegeLab, an algorithm-enabled college admissions service, PreventAGEHealth Care, a diabetes diagnostic firm; CyberBuoy, a cyber insurance and response service; Kings Row Coffee, a cold brew coffee company, and Lutum Technology, a molecularly-imprinted polymer sensor development company focused on industrial, security, and health-care applications. Jamal received his undergraduate degree from Dartmouth College.</p> */}
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Aimahd Sabky</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Principal</h5>
            {/* <p style={{ color: '#555c63' }}>Aimahd is the chief operating officer for Kerflummox and Bema companies and is responsible for product development and operations. Aimahd is a co-founder at CollegeLab and CyberBuoy. Before Kerflummox Capital, Aimahd worked at MakeSpace. Aimahd graduated from Amherst College in 2014 with a degree in Mathematics and from The Middlesex School in Concord, MA in 2010.</p> */}
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Sam Sabky</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Principal</h5>
            {/* <p style={{ color: '#555c63' }}>Sam attended Princeton University, where he played hockey and like most students, drank coffee strictly for the caffeine. It was during his two year minor league hockey career when he discovered his passion for truly great coffee. He lives in New England with his wife, son and English cocker spaniel, and loves pond hockey and nitro cold brew.</p> */}
          </Col>
          {/* <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>S. Caroline Kerr</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Partner Kerflummox Capital - corporate strategy and business development</h5>
            <p style={{ color: '#555c63' }}>Caroline is a builder, connector, and cultivator, who has led organizations through significant phases of growth and innovation. Previously, she served as CEO of the Joyce Ivy Foundation, an organization that broadens college aspirations and leadership development of talented young women, and has deep expertise in the college admissions and education space. She builds and advises organizations on strategy, growth, and governance. Caroline serves on multiple boards, including Dartmouth, Nelson A. Rockefeller Center for Public Policy and Social Sciences, and CollegeLab. Caroline received her undergraduate degree from Dartmouth College and a master’s degree from Harvard.</p>
          </Col> */}
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Kristen Carey</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Director of Operations</h5>
            <p style={{ color: '#555c63' }}></p>
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Sarah Sparks</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Operations</h5>
            <p style={{ color: '#555c63' }}></p>
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Janice Benson</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Operation and Finance</h5>
            <p style={{ color: '#555c63' }}></p>
          </Col>
          {/* <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Stuart Ellman</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>Senior Advisor</h5>
            <p style={{ color: '#555c63' }}></p>
          </Col> */}
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
          </Col>
          <Col xs='12' sm='12' md='4' className='text-center' style={{ marginBottom: '36px' }}>
            <div className='top-bar-dec' />
            <h3 style={{ marginBottom: '16px', marginTop: '24px' }}>Ryan Gardner</h3>
            <h5 style={{ fontWeight: 'normal', marginBottom: '16px' }}>General Counsel/Principal</h5>
            <p style={{ color: '#555c63' }}></p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Team;