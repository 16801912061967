import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Trans } from 'react-i18next';

import './Companies.scss';

const config = [
  {
    title: 'CollegeLab',
    href: 'https://www.collegelab.org',
    logo: 'https://collegelab.sfo2.digitaloceanspaces.com/assets/mstile-144x144.png',
    description: <Trans i18nKey="collegeLabDescription" />
  },
  {
    title: "King's Row Coffee",
    href: 'https://kingsrowcoffee.com/pages/kings-brew',
    logo: 'https://cdn.shopify.com/s/files/1/2997/3352/files/Black_2048x.png?v=1519076401',
    description: <Trans i18nKey="coffeeDescription" />
  },
  {
    title: "King's Brew",
    href: 'https://kingsrowcoffee.com/',
    logo: '//cdn.shopify.com/s/files/1/2997/3352/files/can-original.jpg?331',
    description: <Trans i18nKey="brewDescription" />
  },
  {
    title: 'Bema Holdings',
    href: 'https://www.bemaholdings.com',
    logo: '/images/bema-holdings-logo2.png',
    description: <Trans i18nKey="bemaTechDescription" />,
    fill: true
  },
  // {
  //   title: 'Bema Technologies',
  //   href: 'https://www.bema.dev',
  //   logo: '/images/bema-tech-logo.png',
  //   description: <Trans i18nKey="bemaHoldDescription" />
  // },
  {
    title: "Supersixty Camps",
    href: 'https://supersixtycamps.com/',
    logo: 'https://s3.amazonaws.com/files.leagueathletics.com/Images/Logos/11794_AutoBanner.jpg',
    description: <Trans i18nKey="superSixtyDescription" />,
    fill: true
  },
  {
    title: "NCD Camps",
    href: 'http://www.ncdcamps.com/',
    logo: 'https://s3.amazonaws.com/files.leagueathletics.com/Images/Logos/11228_banner.jpg',
    description: <Trans i18nKey="ncdDescription" />,
    fill: true
  },
  {
    title: "Digital Asset Base",
    href: 'https://dabnft.com/',
    logo: '/images/digital-asset-bank.png',
    description: <Trans i18nKey="dabDescription" />,
    fill: true
  },
  {
    title: "Camp GM",
    href: 'https://campgm.com/',
    logo: '/images/campgm.png',
    description: <Trans i18nKey="campGMDescription" />,
    fill: true
  },
  {
    title: "Social Trace",
    href: 'http://socialtrace.io/',
    logo: '/images/social-trace.png',
    description: <Trans i18nKey="socialTraceDescription" />,
    fill: true
  },
  {
    title: "Blue Hen",
    href: 'http://bluehen.pub/',
    logo: '/images/blue-hen.png',
    description: <Trans i18nKey="blueHenDescription" />,
    fill: true
  },
  {
    title: "342 Innovations Lab",
    href: '',
    logo: '',
    description: <Trans i18nKey="_342LabDescription" />,
    fill: true
  },
]

class Companies extends React.Component {
  renderCompanies() {
    return config.map((c, i) => (
      <Col xs='12' sm='12' md='4' key={`company-${i}`} style={{ textAlign: 'left' }}>
        <div className='logo'>
          <a href={c.href} rel='noopener noreferrer' target='_blank' style={{ padding: '8px', width: c.fill ? "80%" : "140px" }}>
            <div style={{
              backgroundImage: `url(${c.logo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              height: '100%',
              width: '100%'
            }} />
          </a>
        </div>
        <div className='company-card'>
          <h4 style={{ fontSize: '21px', fontWeight: 'bold', marginBottom: '0.75rem', color: 'var(--gray-dark)', textAlign: 'left' }}>{c.title}</h4>
          <div style={{ marginBottom: '0.75rem', textAlign: 'left' }}>{c.description}</div>
          <div style={{ textAlign: 'left' }}>
            <a href={c.href} rel='noopener noreferrer' target='_blank' style={{ color: '#652f8f' }}><b>Learn More</b></a>
          </div>
        </div>
      </Col>
    ));
  }

  render = () => (
    <div className='Companies' id='companies'>
      <Container>
        <Row>
          <Col xs='12' style={{ textAlign: 'center', marginBottom: '2.5rem' }}>
            <h1>Our Companies and Partners</h1>
          </Col>
          <Col className='company' xs='12'>
            <Row>
              {this.renderCompanies()}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Companies