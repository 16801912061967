import React from 'react';
import { Container, Row, Col, Input, Label, FormGroup, Button, Form } from 'reactstrap';

import './Contact.scss';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Contact extends React.Component {
  state = {
    name: '',
    email: '',
    message: ''
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, name, message } = this.state;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", email, name, message })
    }).then(() => {
      this.setState({
        name: '',
        message: '',
        email: ''
      }, () => {
        alert('Thank you for your submission, we will be in touch!');
      });
    }).catch(error => alert(error));
  }

  render = () => (
    <div className='Contact' id='contact'>
      <Form onSubmit={this.handleSubmit} id='contact-form'>
        <Container>
          <Row>
            <Col xs='12'>
              <h1>
                Contact
              </h1>
            </Col>
            <Col xs='6'>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  type='text'
                  required
                  placeholder="" />
              </FormGroup>
            </Col>
            <Col xs='6'>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  type='email'
                  required
                  placeholder="" />
              </FormGroup>
            </Col>
            <Col xs='12'>
              <FormGroup>
                <Label>Message</Label>
                <Input
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  type='textarea'
                  required
                  rows="4"
                  placeholder="" />
              </FormGroup>
            </Col>
            <Col xs='12' style={{ textAlign: 'center', marginBottom: "64px", marginTop: '24px' }}>
              <Button outline variant='primary' size='lg' type="submit">Submit</Button>
            </Col>
            <Col xs='12' className='text-center'>
              Copyright © Kerflummox Capital LLC. All rights reserved.
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  )
}

export default Contact;