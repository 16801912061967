import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          heroText2: "<p>Bema Technologies is an incubator/accelerator in Hanover, NH that provides seed capital, development, operations, management, legal, and finance resources sto portfolio companies in the 0-1 stage. We look to partner with entrepreneurs efficiently and intelligently transform ideas into revenue generating companies. Our model allows us to operate businesses with lean budgets and provide them greater access to resources.</p>",
          collegeLabDescription: "CollegeLab is an artificially intelligent college admissions tool that helps families and counselors better navigate and manage the college admissions process. Built using machine learning methods and the expertise of a former Ivy League Dean of Admissions, CollegeLab utilizes over 4 million student applications with outcomes to tell students their chance of admission at 500 colleges and universities, comparing 12 data points and identifying areas they should focus on to improve your chances.",
          cyberBuoyDescription: "CyberBuoy is an innovative and effective cyber incident response service. It provides peace of mind to companies and their clients, as cyber attacks increase in frequency. CyberBuoy provides rapid help in the event of an incident, makes it easy for people to connect with experts and resources, covers the cost of recovery and remediation and is remarkably low cost.",
          coffeeDescription: "King's Row Coffee Company creates products to fuel your best day, wherever it takes you. We achieve that goal with incredible expertise, unparalleled bean quality and a wine-inspired roasting method mastered over the last 30 years by a James Beard award winning Best Chef.",
          brewDescription: "King's Brew is the perfect companion for your day. Carefully crafted with the world’s best ethically-sourced beans, it is infused with nitrogen for an insanely fresh, flavorful and smooth nitro cold brew.",
          bemaHoldDescription: "Bema Holdings is a joint venture with Kerflummox Capital to create a single development platform for early-stage companies.",
          bemaTechDescription: "Bema Technologies is a 0-1 Engine for Start-Up Companies.",
          superSixtyDescription: "Super Sixty Camps provide a unique opportunity for exposure to NCAA college coaches. Over the past few years Super Sixty has become regarded as one of the country's premier exposure opportunities by campers and coaches alike.",
          ncdDescription: "The NCD Camps were born in response to a need for an efficient and effective arena to both educate players about the NCAA hockey recruiting process and initiate contacts between players and coaches. NCAA coaches run all practice sessions and games and host daily seminars.",
          dabDescription: 'Secure storage and custodial services for digital assets in tax-friendly jurisdictions.',
          campGMDescription: 'A simple and effective management system for camps.',
          socialTraceDescription: 'Uses open source intelligence gathering and proprietary analytics to create an overview of a customer\'s social media presence.',
          blueHenDescription: 'Project management services for short-story and self-published writers.',
          _342LabDescription: 'IP licensing company for Kerflummox-owned patents around ready-to-drink coffee and low acid beverages',

        },
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;