const config = [
  {
    label: 'Home',
    id: 'home',
    onClick: (component) => {
      component.setState({ isOpen: false });
    }
  },
  {
    label: 'Our Companies',
    id: 'companies',
    onClick: (component) => {
      component.setState({ isOpen: false });
    }
  },
  {
    label: 'Our Team',
    id: 'team',
    onClick: (component) => {
      component.setState({ isOpen: false });
    }
  },
]

export default config;