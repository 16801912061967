import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import './Footer.scss';

class Footer extends React.Component {
  render = () => (
    <div className='Footer'>
      <Container>
        <Row>
          <Col xs='12'>
            Copyright {'\u00A9'} Bema Technologies, LLC. All rights reserved.
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer;